import React from 'react';

import '../App.css';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase/firebase.js';
import { Button } from '@mui/material';
import { signOut } from 'firebase/auth';

const Header = ({showSignin, unloadUnityContext}) => {
  const navigate = useNavigate()

  const attempSignOut = async () => {
    try{
      if(unloadUnityContext != null){
        await unloadUnityContext();
      }
      await signOut(auth);
      navigate('/login');
    }catch(err){
      console.error(err);
    }
  }


  return <div className="header">
    {/* <div className="appTitle">Kaputz Games Testing Center</div> */}
    <div className="kaputzlogo" onClick={e=>navigate('/')}></div>
    {!auth.currentUser && <div className="authenticationMenu">
      {showSignin && <button className="signInButton orangebutton" onClick={e=>navigate("/testerapp")}>Become a Tester</button>}
      {showSignin && <button className="signUpButton orangebutton" onClick={e=>navigate("/devlognewsletter")}>DevLog Newsletter</button>}
    </div>}
    {auth.currentUser && <div className="authenticationMenu">
        <span>Logged in: {auth.currentUser.email}</span>
        <Button onClick={e=>attempSignOut()}>Logout</Button>
    </div>}
  </div>
}

export default Header;