import { useState } from 'react'
import { TextField, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import './signup.css';
import { auth } from '../../../firebase/firebase';
import { createUserWithEmailAndPassword} from 'firebase/auth'
const SignUp = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email,setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passconf, setPassconf] = useState('');

    /*
        Attempt to sign up the new user
    */
    const attemptSignup = async () => {
        try{
            // Attempt to sign up using firebase
            await createUserWithEmailAndPassword(auth,email, password);
            
            alert("You've successfully signed up and are now logged in. You may now test our games.");

            // Send them back to where they came from.
            navigate(-1);

        }catch(err){
            console.error(err);
        }
    }
    
    return <>
        {!auth.currentUser && <div className="signup_form">
            <TextField label="Full Name" type="text" placeholder="Full Name" value={name} onChange={e=>setName(e.target.value)}/>
            <TextField label="Email" type="email" placeholder="Email" value={email} onChange={e=>setEmail(e.target.value)}/>
            <TextField label="Password" type="password" placeholder="Password" value={password} onChange={e=>setPassword(e.target.value)}/>
            <TextField label="Password Confirmation" type="password" placeholder="Confirm Password" value={passconf} onChange={e=>setPassconf(e.target.value)}/>
            <Button onClick={e=>attemptSignup()}>Sign Up</Button>
            <Button onClick={e=>navigate(-1)}>Cancel</Button>
        </div>}
        {auth.currentUser && <div>
            <p>You already have an account with us, and you're signed in as {auth.currentUser.email}</p>
            <Button onClick={e=>navigate(-1)}>Back</Button>    
        </div>}
    </>
}

export default SignUp;