import { useNavigate } from "react-router-dom";
import "./game_list_item.css"
const GameListItem = ({name,route,preview,status}) => {
    const navigate = useNavigate();
    return <>
        <span className="game_option_title">{name}</span><br/>
        <div className="game_development_status" style={{fontWeight: status === "Active Development" ? "bold" : status === "Standby" ? "200" : "100" ,color: status === "Active Development" ? "lightgreen" : status === "Standby" ? "yellow" : "red"}}>{status}</div><br/>
        <img alt="game_icon" width="auto" height="100" src={preview}/><br/>
        <button disabled={status === "Active Development" ? false : true} className="orangebutton" onClick={e=>status === "Active Development" ? navigate(route) : alert("Game unavailable at this time.")}>{status === "Active Development" ? "Play" : "Unavailable"}</button>
    </>
}

export default GameListItem;