import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'; 
import TesterSignup from './views/Auth/Signup/tester_signup.js';
import GobbyAndWhipDemo from './views/GobbyAndWhipDemo/gobbyandwhip';
import RingOfEternityDemo from './views/RingOfEternityDemo/ringofeternity';
import DevLogNewsletter from './views/DevlogNewsletter/devlognewsletter';
import Login from './views/Auth/Login/login';
import SignUp from './views/Auth/Signup/signup';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>
  },
  {
    path: "/testerapp",
    element: <TesterSignup/>
  },
  {
    path : "/gobbyandwhipdemo",
    element : <GobbyAndWhipDemo/>
  },
  {
    path : "/ringofeternitydemo",
    element : <RingOfEternityDemo/>
  },
  {
    path : "/devlognewsletter",
    element : <DevLogNewsletter/>
  },
  {
    path : "/login",
    element : <Login/>
  },
  {
    path : '/signup',
    element: <SignUp/>
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router}>

    </RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
