import React from 'react';
import Header from '../../../components/Header';
import './tester_signup.css';
import SignUp from './signup';
const TesterSignup = () => {

    return <div>

        <Header/>
        <div className="testerSignup">
            <h2>Sign up to test our early builds!</h2>
            <h4>How it works:</h4>
            <p>
                When you sign up to become a tester, you'll receive an email or text message whenever a big update is released to the website.
                For now we will only be testing the web version of the game as we tackle the main sources of bugs, gameplay and lore of the game.
                As we get closer to a completed game, we'll begin preparations to release the game on iOS and Android as well. You'll be given an opportunity to become a tester for those platforms as well.
            </p>
            <SignUp/>
            {/* <button onClick={e=>navigate(-1)}>Back</button> */}
        </div>
    </div>
}



export default TesterSignup;