import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyAuOG0xnP-i0a_YtIHA_EXfhfdQk_Ts3f8",
    authDomain: "vigogames.firebaseapp.com",
    databaseURL: "https://vigogames-default-rtdb.firebaseio.com",
    projectId: "vigogames",
    storageBucket: "vigogames.appspot.com",
    messagingSenderId: "608692374832",
    appId: "1:608692374832:web:30165c255abbc74f988cf5"
};



export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);