import './App.css';
import React from 'react';
import { useState } from 'react';
import Header from './components/Header'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import GameListItem from './components/game_list_item';

function App() {
  const [loginDialog,setShowLoginDialog] = useState(false);
  const games = [
    {
      preview_image : "images/images/gobbyandwhipicon.png",
      name : "Gobby and Whip Demo",
      route : "/gobbyandwhipdemo",
      status : "Standby",
    },
    {
      preview_image : "images/images/ringofeternityicon.png",
      name : "Ring of Eternity Demo",
      route : "/ringofeternitydemo",
      status : "Active Development"
    }
  ]

  return <div className="landing">
    <Header showSignin={true}/>
    <video autoPlay muted loop className="background_video">
      <source src="videos/banner_video.mov"></source>
    </video>

    <div className="background_video_overlay"/>

    <div className="content">
      {/* Coming soon to app stores buttons */}
      <div className="downloadButton" style={{backgroundImage: 'url(' + require('./images/comingsoon.png') + ')'}}></div>
      <br/>
      <div className="gameList">
        {/* Game Options Rendering */ }
        {games && games.map((game,index)=><div key={index} className="GameOption">
          <GameListItem name={game.name} route={game.route} preview={game.preview_image} status={game.status}/>
        </div>)}
      </div>


      {/* Sign Up Dialog */}
      <Dialog open={loginDialog} onClose={e=>setShowLoginDialog(false)}>
        <DialogTitle>
          Sign Up
        </DialogTitle>
        <DialogContent>
          <p>
            We need people to help us test the game as we build it. The game will be offered for free on Android and iOS with ads as soon as the development process for the game is completed. To sign up to become a tester for the game simply click the "Become a Tester" button on the main toolbar.
          </p>
        </DialogContent>
        <DialogActions>
          <Button>Sign In</Button>
        </DialogActions>
      </Dialog>
    </div>
  </div>
}

export default App;
