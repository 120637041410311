import React, { Fragment, useEffect, useCallback } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogTitle, DialogActions, Button} from '@mui/material'
import { auth } from "../../firebase/firebase";
import { collection, addDoc } from 'firebase/firestore';
import { firestore } from "../../firebase/firebase";
function RingOfEternityDemo() {
    const navigate = useNavigate();

    const { unityProvider,isLoaded, loadingProgression, unload, addEventListener, removeEventListener } = useUnityContext({
        loaderUrl : "RingOfEternityWeb/Build/RingOfEternityWeb.loader.js",
        dataUrl : "RingOfEternityWeb/Build/RingOfEternityWeb.data",
        frameworkUrl : "RingOfEternityWeb/Build/RingOfEternityWeb.framework.js",
        codeUrl : "RingOfEternityWeb/Build/RingOfEternityWeb.wasm"
    });

    const SendBugReport = useCallback(async (data) => {
        console.log("!!!!!!!!!!!SENDING A BUG REPORT!!!!!!!!!!")
        data = { ...data, email : auth.currentUser.email}
        console.log(data)
        try {
            await addDoc(collection(firestore, 'bug_reports'), {
                transform : data['transform'], 
                gameTime : data['gameTime'],
                reportText : data['reportText'], 
                user : auth.currentUser.email
            });
        }catch(err){
            console.error(err)
        }
      
    },[])

    useEffect(()=>{
        addEventListener('SendBugReport',e=>SendBugReport(e))  

        try{
            auth.onAuthStateChanged(user=>{
                if(user == null){
                    navigate("/login");
                }else{
                }
            })

        }catch(err){
            console.error(err);
        }

        window.onpopstate = async e => {
            await unload();
        }
        
        return async () => {
            
            // await unload()
            removeEventListener("SendBugReport", SendBugReport);
        };
    },[addEventListener, removeEventListener, navigate, SendBugReport])

   

    async function handleClickBack() {
        await unload();
        // Ready to navigate to another page.
        navigate(-1);
    }

    return (
        <Fragment>
            <Header showSignin={false} unloadUnityContext={unload}/>
            {!isLoaded && loadingProgression && (
                <p style={{color:"black", fontWeight: 'bold', background: 'orange', display:'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh'}}>Loading Game... {Math.round(loadingProgression * 100)}%</p>
            )}
            
            {auth.currentUser != null && <div className="content">
                <div className="game_tester_menu">
                    <button className="orangebutton" onClick={handleClickBack}>Return Home</button>
                </div>
                <Unity unityProvider={unityProvider} />
            </div>}

            <Dialog open={auth.currentUser == null}>
                <DialogTitle>
                    Please log in.
                </DialogTitle>
                <DialogContent>
                    <p>Please log in or register to play the game.</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={e=>navigate('/login')}>Login</Button>
                    <Button onClick={e=>navigate('/signup')}>Sign Up</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default RingOfEternityDemo