import './login.css'
import { useState, useEffect } from 'react'
import Header from '../../../components/Header'
import { TextField } from '@mui/material'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../../firebase/firebase'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { sendPasswordResetEmail } from 'firebase/auth'

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [signedIn, setSignedIn] = useState(false);
    const navigate = useNavigate();

    /*
        Here we check for any changes to the user, and update the ui accordingly.
    */
    useEffect(()=>{
        auth.onAuthStateChanged((user)=>{
            if(user != null){
                setSignedIn(true);
            }else{
                setSignedIn(false);
            }
        })
    });

    /*
        Attempt to sign in using firebase auth.
    */
    const attemptLogin = async () => {
        try {
            // Wait for the login logic to complete
            await signInWithEmailAndPassword(auth, email, password);

            // Set them as signed in.
            setSignedIn(true);

            // Return them back to the page they came from
            navigate(-1);
        }catch(err){ // Catch and log any errors.
            console.error(err);
        }
    }

    /*
        Forgot password functionality.
    */
    const forgotPassword = async () => {
        const email = prompt("What's your email address?");
        if(email !== ''){
            await sendPasswordResetEmail(auth, email);
            alert("We've sent you a password recovery email. Please click the link within to continue.");
        }else{
            alert("You must provide an email to begin the account recovery process.");
        }
    }

    

    return <>
        <Header showSignin={true}/>

        {!signedIn && <div className="login_form">
            <TextField label="Email" type="email" placeholder="Email" value={email} onChange={e=>setEmail(e.target.value)}/>
            <br/>
            <TextField label="Password" type="password" placeholder="Password" value={password} onChange={e=>setPassword(e.target.value)}/>
            <br/>
            <Button onClick={e=>attemptLogin()}>Login</Button>
            <Button onClick={e=>navigate("/testerapp")}>Become a Tester</Button>
            <Button onClick={e=>forgotPassword()}>Recover Account</Button>
            <Button onClick={e=>navigate("/")}>Cancel</Button>
        </div>}
        {signedIn && <div className="login_form">
            <p>You are already signed in as ${auth.currentUser.email}</p>
            <Button onClick={e=>navigate(-1)}>Return</Button>
        </div>}
    </>
}

export default Login