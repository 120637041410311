import React, { Fragment } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
function GobbyAndWhipDemo() {
    const navigate = useNavigate();

    const { unityProvider,isLoaded, loadingProgression, unload } = useUnityContext({
        loaderUrl : "GobbyWeb/Build/GobbyWeb.loader.js",
        dataUrl : "GobbyWeb/Build/GobbyWeb.data",
        frameworkUrl : "GobbyWeb/Build/GobbyWeb.framework.js",
        codeUrl :  "GobbyWeb/Build/GobbyWeb.wasm"
    });

    async function handleClickBack() {
        await unload();
        // Ready to navigate to another page.
        navigate(-1);
    }

    return (
        <Fragment>
            <Header style={{clear: 'both'}} showSignin={false}/>
            {!isLoaded && loadingProgression && (
                <p style={{color:"black", fontWeight: 'bold', background: 'orange', display:'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh'}}>Loading Game... {Math.round(loadingProgression * 100)}%</p>
            )}
            <div className="content">
                <button className="orangebutton" onClick={handleClickBack}>Return Home</button>
                <Unity unityProvider={unityProvider} />
            </div>
        </Fragment>
    );
}

export default GobbyAndWhipDemo