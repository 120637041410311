import Header from "../../components/Header"
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material'
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const DevLogNewsletter = () => {
    const [showSignupDialog, setShowSignupDialog] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const navigate = useNavigate();

    const completeSignup = () => {
        // complete the signup process using firebase
    }

    return <>
        <Header showSignin={false}/>
        <div style={{color: 'black', marginTop: 130, padding: 30}}>
            <p>
                Recieve a newsletter email when we upload updates to the game demos.
            </p>
            <button onClick={e=>setShowSignupDialog(true)}>Sign Up</button>
            <button onClick={e=>navigate(-1)}>Back</button>
        </div>
        <Dialog open={showSignupDialog}>
            <DialogTitle>Newsletter Signup</DialogTitle>
            <DialogContent>
                <TextField value={email} placeholder="email" type="email" onChange={e=>setEmail(e.target.value)}></TextField>
                <TextField value={name} placeholder="name" type="text" onChange={e=>setName(e.target.value)}></TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={e=>setShowSignupDialog(false)}>Cancel</Button>
                <Button onClick={e=>completeSignup()}>Sign Up</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default DevLogNewsletter